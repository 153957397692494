<script>
import WorkspaceListsTabTemplate from '@/components/workspace/WorkspaceListsTabTemplate'
import Loading from '../general/Loading.vue'
import ErrorList from '@/components/general/ErrorList'
export default {
  name: 'HomepageUserLists',
  components: {
    WorkspaceListsTabTemplate,
    ErrorList,
    Loading
  },
  data () {
    return {
      isLoading: true,
      errorState: false
    }
  },
  computed: {
    lists () {
      return this.$store.getters.getWorkspaceLists[this.workspaceId]
    },
    workspaceId () {
      return this.selectedWorkspace.type === 'business' ? this.selectedWorkspace.lxID : this.$store.getters.getUser.individualWorkspace ? this.$store.getters.getUser.individualWorkspace.id : null
    }
  },
  methods: {
    updateList (filter = {}) {
      this.$store.dispatch('attemptGetWorkspaceLists', { workspaceId: this.workspaceId, filter }).then((data) => {
        if (data && data.error) this.errorState = true
        this.isLoading = false
      })
    }
  },
  created () {
    this.updateList()
  }
}
</script>
<template>
  <div class="homepage-user-lists--container">
    <Loading class="loading-candidature--wrapper" v-if="isLoading" />
    <error-list :title="this.$t('empty.state.text.published:error.text')" v-else-if="errorState && !isLoading"></error-list>
    <workspace-lists-tab-template v-else :errorState="errorState" :lists="lists" @updateList="updateList"/>
  </div>
</template>
<style lang="scss">
.homepage-user-lists--container {
  padding-bottom: 40px;

  .empty-list{
  margin-top: 20px;
  }

  .loading-candidature--wrapper {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage-user-lists--banner__wrapper {
    text-align: left;
    padding: 50px 24px;
    > * {
      margin: 0;
    }
  }
}
</style>
